import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createStandaloneToast, theme } from '@chakra-ui/react'
import { GRAPHQL_ENDPOINT } from './enviroment'
const customToast = createStandaloneToast({ theme })

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(({ response }) => {
      response?.errors?.forEach(({ message }) => {
        customToast({
          title: 'Es ist ein Fehler aufgetreten',
          description: message,
          duration: 3000,
          status: 'error',
        })
      })

      // this is not a hack: https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
      if (response?.errors) response.errors = undefined
    }),
    new HttpLink({
      // useGETForQueries: true,
      uri: GRAPHQL_ENDPOINT,
    }),
  ]),

  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})
