import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putUpdatePostQuery, putUpdatePostQueryVariables } from './__generated__/putUpdatePostQuery'

export const PUT_UPDATE_POST = gql`
  mutation putUpdatePostQuery(
    $postId: Int!
    $userAccessToken: String!
    $postType: String
    $description: String
    $fileMetas: [imageMetaPostUpdate]
    $tags: [Int]
    $headline: String
  ) {
    updatePost(
      postId: $postId
      userAccessToken: $userAccessToken
      postType: $postType
      description: $description
      fileMetas: $fileMetas
      tags: $tags
      headline: $headline
    ) {
      success
    }
  }
`

export function updatePost (data: putUpdatePostQueryVariables) {
  return apolloClient.query<putUpdatePostQuery, putUpdatePostQueryVariables>({
    query: PUT_UPDATE_POST,
    variables: data,
  })
}

export function useUpdatePost () {
  return useMutation<putUpdatePostQuery, putUpdatePostQueryVariables>(PUT_UPDATE_POST)
}
