
import { CheckCircleIcon } from '@chakra-ui/icons'
import { Container, Flex, Heading, List, ListIcon, ListItem, VStack, Box } from '@chakra-ui/layout'
import type { IconProps } from '@chakra-ui/react'
import { Button, Icon, Image, Skeleton, Tag, TagLabel, Text } from '@chakra-ui/react'
import { IonContent, IonHeader, IonIcon, IonPage } from '@ionic/react'
import { star } from 'ionicons/icons'
import mapboxgl from 'mapbox-gl'
import React, { useMemo, useState } from 'react'
import Map, { Marker } from 'react-map-gl'
import { useParams } from 'react-router'
import Header from '../components/Header'
import HotelMedia from '../components/Hotel/subComponents/HotelMedia'
import Posts from '../components/Posts'
import { useHotel } from '../gql/queries/getHotel'
import { MAPBOX_GL_TOKEN } from '../helper/enviroment'
import { calculateTagColor, sortTags } from '../helper/tags'

// @ts-expect-error Required because it won't work otherwise (thanks ionic, very cool!)
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const CircleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M 12 0 A 12 12 0 1 0 24 12 A 12.014 12.014 0 0 0 12 0 Z Z"
    />
  </Icon>
)

const HotelDetail: React.FC = () => {
  const { id } = useParams<{id: string}>()
  const hotelRes = useHotel(id)
  const hotel = useMemo(() => hotelRes.data?.getTribelloHotel, [hotelRes.data])
  const sortedTags = useMemo(() => (sortTags(hotel?.tags)), [hotel?.tags])
  const listIconChecked = useMemo(() => ({ as: CheckCircleIcon, color: 'var(--ion-color-primary)' }), [])
  const listIconEmpty = useMemo(() => ({ as: CircleIcon, width: '16px', height: '16px', color: 'var(--ion-color-primary)' }), [])

  console.log(sortedTags.map(t => t?.id).filter(Boolean))

  return (
    <IonPage>
      <IonHeader>
        <Header showBackButton/>
      </IonHeader>
      <IonContent fullscreen >
        <Container bgColor="var(--ion-tab-bar-background)" maxW="container.md" p="0" >
          <HotelMedia hotel={hotel}/>
          <VStack spacing="5" align="flex-start" p={5}>
            <Skeleton isLoaded={!hotelRes.loading} >
              <Heading fontWeight="300">{hotel?.name}{' '}
                <Box as="span" display="inline-block">
                  {Array.from(Array(hotel?.stars).keys()).map((e) => (<IonIcon size="small" key={`star-${e}`} icon={star}/>))}
                </Box>
              </Heading>
              {hotel?.country?.isoCode && <Text color="primary.100" mt="2">{hotel.region} ({hotel.country.isoCode})</Text>}
            </Skeleton>
            <Skeleton isLoaded={!hotelRes.loading} w="100%">
              <Button
                as="a"
                href={hotel?.website?.path ?? '#'}
                rounded="full"
                w="full"
                h="50px"
                colorScheme="secondary"
                color="white"
                target={hotel?.websiteTarget ?? '_blank'}
              >{hotel?.website?.text ?? 'Zum Angebot'}
              </Button>
            </Skeleton>
            <Skeleton isLoaded={!hotelRes.loading}>
              <VStack align="flex-start">
                <VStack align="flex-start">
                  <Text fontSize="3xl" fontFamily="Unkempt">Highlights</Text>
                  <List spacing={2}>
                    {hotel?.highlights?.map((highlight, i) => (
                      <ListItem as={Flex} align="center" key={`highlight-${i}`}>
                        <ListIcon {...listIconChecked}/>
                        {highlight?.text}
                      </ListItem>
                    ))}
                  </List>
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="3xl" fontFamily="Unkempt">Hotelbeschreibung</Text>
                  {/* Dangerously set innerhtml because in the future this will be html instead of a string */}
                  <Text dangerouslySetInnerHTML={{ __html: hotel?.description ?? '' }}/>
                </VStack>
                <VStack align="flex-start" w="100%">
                  <Text fontSize="3xl" fontFamily="Unkempt">Kartenansicht</Text>
                  <Map
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxAccessToken={MAPBOX_GL_TOKEN}
                    initialViewState={{
                      latitude: hotel?.geoPoint?.latitude ?? 0,
                      longitude: hotel?.geoPoint?.longitude ?? 0,
                      zoom: 17,
                    }}
                    style={{ width: '100%', height: 400 }}
                    onRender={(map) => map.target.resize({ width: '100%', height: 400 })}
                  >
                    <Marker longitude={hotel?.geoPoint?.longitude ?? 0} latitude={hotel?.geoPoint?.latitude ?? 0} anchor="bottom" >
                      <Image width="48px" height="48px" src="/assets/marker.png"/>
                    </Marker>
                  </Map>
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="3xl" fontFamily="Unkempt">Ausstattung</Text>
                  <List spacing={2}>
                    {hotel?.equipment?.map((equipment, i) => (
                      <ListItem as={Flex} align="center" key={`equipment-${i}`}>
                        <ListIcon {...listIconChecked}/>
                        {equipment?.text}
                      </ListItem>
                    ))}
                  </List>
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="3xl" fontFamily="Unkempt">Verpflegung</Text>
                  <List spacing={2}>
                    <ListItem as={Flex} align="center">
                      <ListIcon {...(hotel?.board?.includes('ohne Verpflegung') ? listIconChecked : listIconEmpty)}/>
                      ohne Verpflegung
                    </ListItem>
                    <ListItem as={Flex} align="center">
                      <ListIcon {...(hotel?.board?.includes('Frühstück') ? listIconChecked : listIconEmpty)}/>
                      Frühstück
                    </ListItem>
                    <ListItem as={Flex} align="center">
                      <ListIcon {...(hotel?.board?.includes('Halbpension') ? listIconChecked : listIconEmpty)}/>
                      Halbpension
                    </ListItem>
                    <ListItem as={Flex} align="center">
                      <ListIcon {...(hotel?.board?.includes('Vollpension') ? listIconChecked : listIconEmpty)}/>
                      Vollpension
                    </ListItem>
                  </List>
                </VStack>
                <VStack align="flex-start">
                  <Text fontSize="3xl" fontFamily="Unkempt">Tags</Text>
                  <Flex w="full" flexWrap="wrap" gap={2}>
                    {sortedTags.map((tag, i) => (
                      <Tag size="lg" borderRadius="full" colorScheme={calculateTagColor(tag?.tagType?.name ?? '')} key={tag?.id}>
                        <TagLabel>{tag?.name}</TagLabel>
                      </Tag>
                    ))}
                  </Flex>
                </VStack>
              </VStack>
            </Skeleton>
          </VStack>
        </Container>
        {/* OR condition but filter out "österreich" tag and only filter for region tags */}
        <Posts hideFilter={true} type="post" filter={{ tags: JSON.stringify(hotel?.tags?.map(tag => (tag?.tagType?.id === '2292' && tag.id !== '1280') && tag.id)), tagCondition: 'OR' }}/>
      </IonContent>
    </IonPage>
  )
}

export default HotelDetail
