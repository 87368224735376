import { Container } from '@chakra-ui/react'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import React from 'react'
import Header from '../components/Header'
import InspirationElement from '../components/Inspiration'
import { useInspirationList } from '../gql/queries/getInspirations'

const InspirationList: React.FC = () => {
  const inspirationData = useInspirationList()
  const inspirationEdges = inspirationData.data?.getInspirationListing?.edges

  return (
    <IonPage>
      <IonHeader>
        <Header title="Inspiration"/>
      </IonHeader>
      <IonContent>
        <Container maxW="container.md" p="0">
          {inspirationEdges?.map((inspiration, index) => (
            <InspirationElement
              key={`inspo-${index}${inspiration?.node?.id ?? ''}`}
              inspiration={inspiration?.node}
            />
          ))}
        </Container>
      </IonContent>
    </IonPage>
  )
}
export default InspirationList
