/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Heading, SkeletonText, Text } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { useMeasure } from 'react-use'
import type { getInspirationListQuery_getInspirationListing_edges_node as IInspiration } from '../../../gql/queries/__generated__/getInspirationListQuery'
import Interactions from '../../Interactions'

const InspirationContent: React.FC<{inspiration: IInspiration | null | undefined, likeInspiration: (value: boolean) => void}> = ({ likeInspiration, inspiration }) => {
  const [textRef, { height: textHeight }] = useMeasure<HTMLParagraphElement>()
  const spanRef = useRef<HTMLSpanElement>(null)

  const isTextCut = useMemo(() => {
    if (!textHeight || !spanRef.current) {
      return false
    }
    return textHeight < spanRef.current.offsetHeight
  }, [textHeight, spanRef.current])

  if (!inspiration) {
    return (
      <Box padding="20px">
        <SkeletonText mt="4" noOfLines={4} spacing="4"/>
      </Box>
    )
  }

  return (
    <Box padding="20px" mb="1em">
      <Heading size="md" mb={2}>{inspiration.name}</Heading>
      <Text ref={textRef} mb="5px" whiteSpace="pre-line" noOfLines={3}>
        <span ref={spanRef} dangerouslySetInnerHTML={{ __html: inspiration.teaser ?? '' }}/>
      </Text>
      {isTextCut && (
        <Text fontSize="sm" mb="10px" opacity={0.5} _hover={{ textDecor: 'underline' }}>
          Mehr lesen
        </Text>
      )}
      <Interactions onLiked={likeInspiration} inspiration={inspiration}/>
    </Box>
  )
}

export default InspirationContent
