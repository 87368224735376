import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react'
import { useAnimation } from 'framer-motion'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import type { getHotelQuery_getTribelloHotel } from '../../gql/queries/__generated__/getHotelQuery'
import HotelContent from './subComponents/HotelContent'
import HotelMedia from './subComponents/HotelMedia'

const Hotel: React.FC<{hotel: getHotelQuery_getTribelloHotel | null | undefined }> = ({ hotel }) => {
  const history = useHistory()
  const animationControl = useAnimation()

  const likeHotelAnimation = useCallback((value: boolean) => {
    if (!value) return

    animationControl.start({
      opacity: [1, 1, 1, 0],
      scale: [0, 1, 1, 0],
    })
  }, [animationControl])

  return (
    <Box
      borderRadius="8px"
      bg="var(--ion-card-background)"
      m="16px"
      transition=".35s"
      cursor="pointer"
      border="2px solid transparent"
      _hover={{
        border: '2px solid rgba(var(--ion-color-primary-rgb), 0.2)',
      }}
      _active={{
        border: '2px solid var(--ion-color-primary)',
      }}
      onClick={() => history.push(`/hotels/${hotel?.id ?? ''}`)}
    >
      <Flex justifyContent="space-between" p="1em" align="center">
        <Flex align="center" onClick={e => e.stopPropagation()}>
          <Box>
            <HStack spacing="3">
              <Flex flexDir="column">
                <Heading size="sm" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  Hotel
                </Heading>
                <Flex align="center">
                  <Text size="xs" opacity={0.55}>
                    {hotel?.name}
                  </Text>
                </Flex>
              </Flex>
            </HStack>
          </Box>
        </Flex>
      </Flex>
      <HotelMedia hotel={hotel} animationControl={animationControl}/>
      <HotelContent hotel={hotel} likeHotel={likeHotelAnimation}/>
    </Box>
  )
}

export default Hotel
