import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putDeleteCommentQuery, putDeleteCommentQueryVariables } from './__generated__/putDeleteCommentQuery'

const PUT_DELETE_COMMENT = gql`
mutation putDeleteCommentQuery($commentID: Int!, $userAccessToken: String!) {
  deleteComment(commentId: $commentID, userAccessToken: $userAccessToken) {
    success
  }
}`
export function deleteComment (commentId: string | number, userAccessToken: string) {
  return apolloClient.query<putDeleteCommentQuery, putDeleteCommentQueryVariables>({
    query: PUT_DELETE_COMMENT,
    variables: {
      commentID: Number(commentId),
      userAccessToken,
    },
  })
}

export function useDeleteComment () {
  return useMutation<putDeleteCommentQuery, putDeleteCommentQueryVariables>(PUT_DELETE_COMMENT)
}
