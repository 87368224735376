import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import { POST_FRAGMENT } from './getPost'
import type { getInspirationItemQuery, getInspirationItemQueryVariables } from './__generated__/getInspirationItemQuery'
import type { getInspirationListQuery } from './__generated__/getInspirationListQuery'

export const GET_INSPIRATION_LIST = gql`
query getInspirationListQuery {
  getInspirationListing {
    ... on InspirationConnection {
      edges {
        node {
          id
          name
          teaser
          likesCount
          images {
            image {
              fullpath(thumbnail:"hotel")
              mimetype
            }
          }
          content {
            ... on fieldcollection_InspirationGallery {
              gallery {
                image {
                  fullpath
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export const GET_INSPIRATION_ITEM = gql`
query getInspirationItemQuery ($ID: Int!, $includeProfileData: Boolean = false) {
  getInspiration(id: $ID) {
    id
    name
    teaser
    likesCount
    content {
      ... on fieldcollection_InspirationText {
        __typename
        headline
        text
      }
      ... on fieldcollection_InspirationGallery {
        __typename
        gallery {
          image {
            fullpath(thumbnail: "hotel")
            mimetype
          }
        }
      }
      ... on fieldcollection_InspirationButton {
        __typename
        buttonText
        buttonUrl {
          text
          path
        }
        buttonTarget
      }
      ... on fieldcollection_InspirationDogStories {
        __typename
        posts {
          ...PostFragment
        }
      }
    }
  }
}
${POST_FRAGMENT}
`

export async function getInspirationList () {
  return apolloClient.query<getInspirationListQuery>({
    query: GET_INSPIRATION_LIST,
  })
}

export async function getInspirationItem (ID: number | string) {
  return apolloClient.query<getInspirationItemQuery, getInspirationItemQueryVariables>({
    query: GET_INSPIRATION_ITEM,
    variables: {
      ID: Number(ID),
    },
  })
}

export function useInspirationList () {
  return useQuery<getInspirationListQuery>(GET_INSPIRATION_LIST)
}

export function useInspirationItem (ID: number | string) {
  return useQuery<getInspirationItemQuery, getInspirationItemQueryVariables>(GET_INSPIRATION_ITEM, {
    variables: {
      ID: Number(ID),
    },
  })
}
