import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putCreateCommentQuery, putCreateCommentQueryVariables } from './__generated__/putCreateCommentQuery'

export const PUT_CREATE_COMMENT = gql`
  mutation putCreateCommentQuery($postId: Int!, $content: String!, $userAccessToken: String!) {
  createComment(postId: $postId,  content: $content, userAccessToken: $userAccessToken) {
    success,
    comment {
      __typename
      id
      content
      likesCount
      post {
        ... on object_TribelloPost {
          id
        }
      }
      user {
        ...on object_CoreShopUser {
          id
          customer {
            ...on object_CoreShopCustomer {
              id
              firstname
              lastname
              images {
                ... on asset {
                  fullpath(thumbnail: "content")
                  mimetype
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export function createComment (postId: string | number, content: string, userAccessToken: string) {
  return apolloClient.query<putCreateCommentQuery, putCreateCommentQueryVariables>({
    query: PUT_CREATE_COMMENT,
    variables: {
      postId: Number(postId),
      content,
      userAccessToken,
    },
  })
}

export function useCreateComment () {
  return useMutation<putCreateCommentQuery, putCreateCommentQueryVariables>(PUT_CREATE_COMMENT)
}
