import { Avatar, Box, Container, Flex, FormControl, Heading, HStack, Input, Textarea, VStack } from '@chakra-ui/react'
import { css } from '@emotion/css'
import type Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { getCompressedImageUrl } from '../../../helper/asset'
import { useUser } from '../../../helper/auth'

interface IProps {
  headline: string
  description: string
  onHeadlineChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  uppy: Uppy
  isValid: { valid: boolean, message: string }
}

const MediaUpload: React.FC<IProps> = ({ uppy, description, headline, onHeadlineChange, onDescriptionChange, isValid }) => {
  const user = useUser()
  return (
  // overflow is a hack because ionic modal is not scrollable otherwise
    <Flex flexDir="column" justify="space-between" h="100%" overflowY="auto">
      <VStack w="100%">
        <Container pt={3} h="100%">
          <HStack spacing={5} width="100%" align="flex-start">
            <Box><Avatar src={getCompressedImageUrl(user.data?.user?.customer?.images?.[0]?.fullpath)}/></Box>
            <Box w="100%">
              <Heading size="md">{user.data?.user?.customer?.firstname} {user.data?.user?.customer?.lastname}</Heading>
              <FormControl>
                <Input _focus={{ outline: 'none' }} outline="none" border="none" borderColor="whiteAlpha.600" paddingInlineStart={0} pt={4} pb={4} placeholder="Titel *" onChange={onHeadlineChange} value={headline}/>
              </FormControl>
              <FormControl>
                <Textarea
                  onChange={onDescriptionChange}
                  value={description}
                  autoFocus
                  fontWeight="light"
                  as={TextareaAutosize}
                  resize="none"
                  placeholder="Schreibe etwas... *"
                  w="100%"
                  borderWidth={0}
                  p="7px 0 0 0"
                  outline="none"
                  _focus={{ outline: 'none' }}
                />
              </FormControl>
            </Box>
          </HStack>
        </Container>
      </VStack>
      <Box
        alignSelf="stretch"
        width="100%"
        className={css`
              & div .uppy-Dashboard-inner {
                border: 0;
                border-radius: 0;
              }
              & .uppy-DashboardContent-back {
                visibility: hidden;
              }
            `}
      >
        <Dashboard
          hideCancelButton={true}
          className={css`
            width: 100%;
          `}
          width="100%"
          hideUploadButton={true}
          uppy={uppy}
          proudlyDisplayPoweredByUppy={false}
          theme="auto"
        />
      </Box>
    </Flex>
  )
}

export default MediaUpload
