import { gql, useMutation, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putNotificationQuery, putNotificationQueryVariables } from './__generated__/putNotificationQuery'

const PUT_NOTIFICATION = gql`
  mutation putNotificationQuery($token: String! $postId: Int! $receiverId: Int!, $notificationType: String!) {
    createNotification(
      postId: $postId
      receiverId: $receiverId
      notificationType: $notificationType
      userAccessToken: $token
  ) {
      success 
    }
  }
`

export function createNotification (token: string, postId: number, receiverId: number, notificationType: 'postLiked' | 'postBookmarked' | 'postCommented' | 'postShared' | 'mentioned') {
  return apolloClient.mutate<putNotificationQuery, putNotificationQueryVariables>({
    mutation: PUT_NOTIFICATION,
    variables: {
      token,
      postId,
      receiverId,
      notificationType,
    },
  })
}

export function useCreateNotification (token: string, postId: number, receiverId: number, notificationType: 'postLiked' | 'postBookmarked' | 'postCommented' | 'postShared') {
  return useMutation<putNotificationQuery, putNotificationQueryVariables>(PUT_NOTIFICATION, {
    variables: {
      token,
      postId,
      receiverId,
      notificationType,
    },
  })
}
