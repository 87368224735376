import { Avatar, Box, Center, Flex, Heading, HStack, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import React, { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import type { ICardProps } from '../..'
import { getAssetUrl, getCompressedImageUrl } from '../../../../helper/asset'
import PostHeaderEdit from './subComponents/PostHeaderEdit'
import PostHeaderMenu from './subComponents/PostHeaderMenu'
import { chakra } from '@chakra-ui/system'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import locale from 'dayjs/locale/de'
import type { IPopOverState } from '../../../../types/IPopOverState'
import { useUser } from '../../../../helper/auth'
void locale // so it doesnt get omited from build

const ChakraIcon = chakra(IonIcon)
dayjs.extend(relativeTime)
const PostHeader: React.FC<ICardProps & {showBackButton?: boolean}> = ({ post, onDeleted, onEdit, showBackButton }) => {
  const user = useUser()
  const [popoverState, setShowPopover] = useState<IPopOverState>({ showPopover: false })
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const goBack = useMemo(() => {

  }, [document.referrer])

  const navigateBack = useCallback(() => {
    if (document.referrer.includes('localhost') || document.referrer.includes('tribello') || document.referrer === '') {
      window.history.back()
    } else {
      history.push('/posts')
    }
  }, [])

  if (!post?.user) {
    return (
      <HStack p="1em" spacing="3">
        <SkeletonCircle size="10"/>
        <Skeleton height="20px" w="100%"/>
      </HStack>
    )
  }

  return (
    <Flex justifyContent="space-between" p="1em" align="center">
      <Flex align="center" onClick={e => e.stopPropagation()}>
        {showBackButton && (
          <Center cursor="pointer" onClick={() => navigateBack()}>
            <ChakraIcon icon={arrowBack} size="large" mr={3}/>
          </Center>
        )}
        <Box role="group" as={RouterLink} to={user.isLoggedIn ? `/profile/${post.user.id ?? 0}` : '/login'}>
          <HStack spacing="3">
            <Avatar src={getCompressedImageUrl(post.user.customer?.images?.[0]?.fullpath)}/>
            <Flex flexDir="column">
              <Heading size="sm" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" _groupHover={{ textDecor: 'underline' }}>
                {post.user.customer?.firstname} {post.user.customer?.lastname}
              </Heading>
              <Flex align="center">
                <Text size="xs" opacity={0.55}>
                  {dayjs(post.dateTime).locale('de').fromNow()}
                </Text>
              </Flex>
            </Flex>
          </HStack>
        </Box>
      </Flex>
      <PostHeaderMenu post={post} popoverState={popoverState} showModal={showModal} setShowPopover={setShowPopover} setShowModal={setShowModal} onDeleted={onDeleted}/>
      <PostHeaderEdit post={post} showModal={showModal} setShowModal={setShowModal} onEdit={onEdit}/>
    </Flex>
  )
}

export default PostHeader
