import { Box, Button, Container, Flex, Heading, Image, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import Header from '../components/Header'
import { MotionFlex } from '../components/Motion'
import { useInspirationItem } from '../gql/queries/getInspirations'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { getCompressedImageUrl } from '../helper/asset'
import Post from '../components/Post'

const InspirationDetail: React.FC = () => {
  const { id } = useParams<{id: string}>()
  const imageBg = useColorModeValue('gray.50', '#181818')
  const [activeIndex, setActiveIndex] = useState(0)

  const inspirationData = useInspirationItem(id)

  // TODO: Split sections into components
  return (
    <IonPage>
      <IonHeader>
        <Header showBackButton title="Inspiration"/>
      </IonHeader>
      <IonContent fullscreen>
        <Container maxW="container.md" p="0">
          {inspirationData.data?.getInspiration?.content?.map((item, index) => {
            if (item?.__typename === 'fieldcollection_InspirationText') {
              return (
                <VStack key={`dogstack-${index}`} spacing="5" align="flex-start" p={5}>
                  <Heading size="md" mb={2}>{item.headline}</Heading>
                  <Text mb="5px" whiteSpace="pre-line">
                    <span dangerouslySetInnerHTML={{ __html: item.text ?? '' }}/>
                  </Text>
                </VStack>
              )
            } else if (item?.__typename === 'fieldcollection_InspirationGallery') {
              return (
                <Box onClick={e => e.stopPropagation()} bg={imageBg}>
                  <Swiper
                    zoom={{
                      maxRatio: 5,
                      minRatio: 1,
                    }}
                    scrollbar={{ hide: true }}
                    navigation
                    style={{ width: '100%' }}
                    virtual={true}
                    preloadImages={false}
                    onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                  >
                    {
                      item.gallery?.map((image, index) => image?.image?.fullpath && image.image.mimetype && (
                        <SwiperSlide key={`dogmedia-${index}`}>
                          <Flex
                            position="relative"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center"
                            cursor="pointer"
                          >
                            <MotionFlex
                              initial={{ opacity: 0 }}
                              pointerEvents="none"
                              position="absolute"
                              color="red.400"
                              fontSize="150px"
                              h="100%"
                              w="100%"
                              top="0"
                              left="0"
                              justifyContent="center"
                              alignItems="center"
                              zIndex={3}
                            />
                            <Box className="swiper-zoom-container" h={['200px', '300px', '400px']}>
                              <Image w="100%" h="100%" src={getCompressedImageUrl(image.image.fullpath)} key={`dog-picture-${index}`}/>
                            </Box>
                          </Flex>
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </Box>
              )
            } else if (item?.__typename === 'fieldcollection_InspirationButton') {
              return (
                <Box p={5}>
                  <Button mb="20px" colorScheme="primary" className="findme" w="full" as="a" target={item.buttonTarget ?? '_blank'} href={item.buttonUrl?.path ?? '#'}>{item.buttonText ?? item.buttonUrl?.text}</Button>
                </Box>
              )
            } else if (item?.__typename === 'fieldcollection_InspirationDogStories') {
              return (
                // TODO: change to feed component (Posts)
                <Box>
                  {item.posts?.map((post, index) => {
                    return <Post key={`dogdetailposts-${index}${post?.id ?? ''}`} post={post}/>
                  })}
                </Box>
              )
            }
          },
          )}
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default InspirationDetail
