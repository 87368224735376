import { Avatar, Box, Flex, Heading, HStack } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { deleteComment } from '../../gql/mutations/putDeleteComment'
import type { getCommentsQuery_getTribelloCommentListing_edges_node } from '../../gql/queries/__generated__/getCommentsQuery'
import { getAssetUrl, getCompressedImageUrl } from '../../helper/asset'
import { getAuthToken, useUser } from '../../helper/auth'
import type { IPopOverState } from '../../types/IPopOverState'
import CommentContent from './subComponents/CommentContent'
import CommentContentEditing from './subComponents/CommentContentEditing'
import CommentPopover from './subComponents/CommentPopover'
import { Link as RouterLink } from 'react-router-dom'

interface IProps {
  comment: getCommentsQuery_getTribelloCommentListing_edges_node
  onDelete: (id: string) => void
}
const Comment: React.FC<IProps> = ({ comment, onDelete }) => {
  const user = useUser()
  const [popoverState, setShowPopover] = useState<IPopOverState>({ showPopover: false })
  const [commentEdit, setCommentEdit] = useState<getCommentsQuery_getTribelloCommentListing_edges_node | undefined>()
  const [editing, setEditing] = useState(false)
  const textRef = useRef<HTMLTextAreaElement>(null)

  const handleCommentDelete = useCallback(async () => {
    const token = getAuthToken()
    if (!comment.id || !token) return
    deleteComment(comment.id, token)
    setShowPopover({ showPopover: false })
    onDelete(comment.id)
  }, [comment, onDelete])

  const handleCommentEdit = useCallback((content: string) => {
    setCommentEdit({ ...comment, content })
  }, [comment])

  useEffect(() => {
    if (textRef.current && textRef.current !== document.activeElement) {
      textRef.current.focus()
    }
  })

  return (
    <HStack
      spacing="5"
      alignItems="flex-start"
      borderRadius="8px"
      bg="var(--ion-card-background)"
      p="16px"
    >
      <Avatar src={getCompressedImageUrl(comment.user?.customer?.images?.[0]?.fullpath)}/>
      <Box w="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as={RouterLink} to={user.isLoggedIn ? `/profile/${comment.user?.id ?? 0}` : '/login'} d="flex" justifyContent="space-between" size="md" _hover={{ textDecor: 'underline' }}>{comment.user?.customer?.firstname} {comment.user?.customer?.lastname}</Heading>
          <CommentPopover comment={comment} deleteComment={handleCommentDelete} setEdit={setEditing} popoverState={popoverState} setShowPopover={setShowPopover}/>
        </Flex>
        {editing
          ? <CommentContentEditing onCommentEdited={handleCommentEdit} commentId={comment.id ?? 0} setEditing={setEditing} value={commentEdit?.content ?? comment.content ?? ''}/>
          : <CommentContent comment={commentEdit ?? comment}/>}
      </Box>
    </HStack>
  )
}

export default Comment
