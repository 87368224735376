import { Input } from '@chakra-ui/input'
import { Box, Center, Container, Flex, VStack } from '@chakra-ui/layout'
import { Button, Checkbox, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Link, Skeleton, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import qs from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { useRegistrationDetails } from '../gql/queries/getRegistrationDetails'
import { useUser } from '../helper/auth'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const [loading, setLoading] = useState(false)
  const user = useUser()
  const toast = useToast()
  const history = useHistory()
  const { search } = useLocation()
  const parsedQuery = qs.parse(search)
  const logo = useColorModeValue('/assets/logo.svg', '/assets/logo-white.svg')
  const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    const res = isLogin ? await user.login?.(email, password) : await user.register?.(password, passwordRepeat, firstName, lastName, email, email, 'de', termsAccepted)
    setLoading(false)
    if (res?.userId) {
      toast({
        duration: 3000,
        position: 'bottom',
        description: 'Du bist nun eingeloggt',
        status: 'success',
        title: 'Erfolg',
      })
      return history.push(`/profile/${res.userId}`)
    }
  }, [email, history, password, user])

  useEffect(() => {
    parsedQuery.email && setEmail(parsedQuery.email as string)
    parsedQuery.firstname && setFirstName(parsedQuery.firstname as string)
    parsedQuery.lastname && setLastName(parsedQuery.lastname as string)
    parsedQuery.register && setIsLogin(false)
  }, [parsedQuery])

  const checkPasswordValid = useCallback(() => {
    return /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[^A-Za-z0-9]/.test(password) &&
    password.length > 8
  }, [password])

  const { data: registrationPageDetails, loading: detailsLoading } = useRegistrationDetails()

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg-clear">
        <Container maxW="container.sm" bg="whites">
          <VStack spacing={45} mt={15} alignItems="stretch">
            <Center flexDir="column">
              <IonImg src={logo} alt="Tribello Logo"/>
            </Center>
            <form onSubmit={e => handleSubmit(e)}>
              <VStack spacing={3}>
                {isLogin
                  ? (
                    <VStack spacing={3} w="100%">
                      <FormControl id="email">
                        <FormLabel>E-Mail</FormLabel>
                        <Input type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        <FormHelperText>Deine E-Mail Adresse.</FormHelperText>
                      </FormControl>
                      <FormControl id="password">
                        <FormLabel>Passwort</FormLabel>
                        <Input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                        <FormHelperText>Passwort <Box as={Link} href="/forgot" color="primary.500" _hover={{ textDecoration: 'underline' }}>vergessen?</Box></FormHelperText>
                      </FormControl>
                    </VStack>
                    )
                  : (
                    <VStack spacing={3}>
                      <Box fontSize="sm" width="100%">
                        <Skeleton isLoaded={!detailsLoading}>
                          <Heading mb="20px" fontFamily="Unkempt" textAlign="left">{registrationPageDetails?.getTribelloContentPage?.name}</Heading>
                        </Skeleton>
                        <Skeleton isLoaded={!detailsLoading}>
                          <Box
                            mb="30px"
                            css={css`
                            p {
                              margin-bottom: 10px;
                            }
                            ul { 
                              padding-left: 15px; 
                              margin-bottom: 10px;
                            }
                            strong {
                              font-weight: bold;
                            }
                            `}
                            dangerouslySetInnerHTML={{ __html: registrationPageDetails?.getTribelloContentPage?.content ?? '' }}
                          />
                        </Skeleton>
                      </Box>
                      <Flex w="100%">
                        <FormControl id="firstName" pr="4">
                          <FormLabel>Vorname</FormLabel>
                          <Input required type="text" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                        </FormControl>
                        <FormControl id="lastName">
                          <FormLabel>Nachname</FormLabel>
                          <Input required type="text" value={lastName} onChange={e => setLastName(e.target.value)}/>
                        </FormControl>
                      </Flex>
                      <FormControl id="email">
                        <FormLabel>E-Mail</FormLabel>
                        <Input required type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                        <FormHelperText>Deine E-Mail Adresse.</FormHelperText>
                      </FormControl>
                      <FormControl id="password" isInvalid={password !== '' && !checkPasswordValid()}>
                        <FormLabel>Passwort</FormLabel>
                        <Input required type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                        <FormHelperText>Dein gewünschtes Passwort.</FormHelperText>
                        <FormErrorMessage>Das Password muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Nummer enthalten.</FormErrorMessage>
                      </FormControl>
                      <FormControl id="passwordRepeat" isInvalid={passwordRepeat !== '' && passwordRepeat !== password}>
                        <FormLabel>Passwort wiederholen</FormLabel>
                        <Input required type="password" value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)}/>
                        <FormHelperText>Passwort bestätigen.</FormHelperText>
                        <FormErrorMessage>Passwörter stimmen nicht überein.</FormErrorMessage>
                      </FormControl>
                      <FormControl id="terms" isInvalid={!termsAccepted}>
                        <Checkbox required isChecked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} colorScheme="primary">
                          Ich habe die <Link textDecor="underline" as={RouterLink} to="/content/agb">AGB</Link> und die <Link as={RouterLink} textDecor="underline" to="/content/datenschutzerklärung">Datenschutzerklärung</Link> gelesen und akzeptiere sie.
                        </Checkbox>
                        <FormErrorMessage>Dieses Feld ist erforderlich.</FormErrorMessage>
                      </FormControl>
                    </VStack>
                    )}
                <Flex w="100%" alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
                  <Button w="100%" isLoading={loading} colorScheme="primary" type="submit">{isLogin ? 'Anmelden' : 'Registrieren'}</Button>
                  <Text fontSize="14px" mt="20px" color="#FFFFFF7A" lineHeight="1.4em">{isLogin ? 'Noch kein Account?' : 'Bereits registriert?'}</Text>
                  <Button w="100%" mt="12px" mb="80px" isLoading={loading} cursor="pointer" onClick={() => setIsLogin(!isLogin)}>{isLogin ? 'Registrieren' : 'Einloggen'} </Button>
                </Flex>
              </VStack>
            </form>
          </VStack>
        </Container>
      </IonContent>
    </IonPage>

  )
}

export default Login
