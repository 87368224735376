import { Box, Center, chakra, Grid, GridItem, Heading, HStack, Image, Skeleton, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { chatbubblesSharp, heartSharp, playSharp, textSharp } from 'ionicons/icons'
import React, { useMemo } from 'react'
import type { getPostsQuery } from '../gql/queries/__generated__/getPostsQuery'
import type { getUserByIdQuery_userById_user_customer as ICustomer } from '../gql/queries/__generated__/getUserByIdQuery'
import { getAssetUrl, getCompressedImageUrl } from '../helper/asset'
const ChakraIcon = chakra(IonIcon)

const UserPosts: React.FC<{customer?: ICustomer | null, posts?: getPostsQuery}> = ({ customer, posts }) => {
  const [isDesktop] = useMediaQuery('(min-width: 1280px)')
  let thumbnail: string | null | undefined = null
  if (!posts || !customer) return null

  return (
    <Box>
      <Heading size="md" marginBottom={3}>{`${customer.firstname ?? 'John Doe'}${customer.firstname?.endsWith('s') ? '' : 's'} Dogstories`}</Heading>
      <Grid templateColumns="repeat(3, 1fr)" gap="2px">
        {posts.getTribelloPostListing?.edges?.map(post => {
          // check if videos exist, else use image for src
          if (post?.node?.videos?.length) {
            thumbnail = post.node.videos[0]?.thumbnail
          } else if (post?.node?.images?.length) {
            thumbnail = post.node.images[0]?.fullpath
          }

          // TODO: maybe outsource this to a component
          return (
            <GridItem pos="relative" alignItems="flex-end" __css={{ aspectRatio: '1' }} key={post?.node?.id ?? '1'} role="group">
              {/* <Skeleton isLoaded={!!mediaElem} w={mediaElem ? '100%' : ['125px', '180px', '250px']} h={mediaElem ? '100%' : ['125px', '180px', '250px']}> */}
              <IonRouterLink href={`/posts/${post?.node?.id ?? '0'}`} color="inherit">
                {isDesktop && (
                  <Box pos="absolute" top="0" bottom="0" left="0" right="0" h="100%" w="100%" opacity="0" transition=".3s ease" _groupHover={{ opacity: 1, backdropFilter: 'blur(5px)' }} zIndex={10}>
                    <Center h="100%">
                      <HStack spacing={5} fontSize="1.7em">
                        <HStack >
                          <IonIcon icon={heartSharp}/>
                          <Text>{post?.node?.likesCount ?? '0'}</Text>
                        </HStack>
                        <HStack>
                          <IonIcon icon={chatbubblesSharp}/>
                          <Text>{post?.node?.commentsCount ?? '0'}</Text>
                        </HStack>
                      </HStack>
                    </Center>
                  </Box>
                )}
                <Image width="100%" height="100%" objectFit="cover" src={getCompressedImageUrl(thumbnail)}/>
                {post?.node?.postType === 'video' && (
                // TODO: Add Drop Shadow to better distinguish icon from background
                  <ChakraIcon pos="absolute" top="0" left="0" zIndex="5" color="white" cursor="pointer" size="large" icon={playSharp}/>
                )}
                {!post?.node?.postType && (
                  <Box alignItems="flex-end" display="flex" w="100%" h="100%" top="0" left="0" p={3} pos="absolute" background="linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3393732492997199) 55%, rgba(0,212,255,0) 100%)" whiteSpace="normal">
                    <Text color="white" fontSize={['sm', 'md', 'lg', 'xl']} >{post?.node?.description}</Text>
                    <ChakraIcon pos="absolute" top="3" left="3" zIndex="5" color="white" size="large" icon={textSharp}/>
                  </Box>
                )}
              </IonRouterLink>
              {/* </Skeleton> */}
            </GridItem>
          )
        })}
      </Grid>
    </Box>

  )
}

export default UserPosts
