import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import { POST_FRAGMENT } from '../queries/getPost'
import type { putCreatePostQuery, putCreatePostQueryVariables } from './__generated__/putCreatePostQuery'

export interface IPostMeta {
  headline: string
  description: string
  postType: string // todo: specific type
  userAccessToken: string
  fileMetas: Array<{name: string, url: string, mimeType: string}>
}

const PUT_CREATE_POST = gql`
  mutation putCreatePostQuery(
  $fileMetas: [imageMetaPostCreate]
  $headline: String!
  $description: String!
  $userAccessToken: String!
  $postType: String!
  $includeProfileData: Boolean = false
  $tags: [Int]
  $lat: Float!
  $lng: Float!
){
  createPost(
    lat: $lat
    lng: $lng
    fileMetas: $fileMetas
    headline: $headline
    description: $description
    userAccessToken: $userAccessToken
    postType: $postType
    tags: $tags
  ) {
    success
    exception
    post {
      ...PostFragment
    }
  }
}

${POST_FRAGMENT}
`

export function createPost ({ description, userAccessToken, headline, postType, fileMetas, tags, lat, lng }: putCreatePostQueryVariables) {
  return apolloClient.query<putCreatePostQuery, putCreatePostQueryVariables>({
    query: PUT_CREATE_POST,
    variables: {
      lat,
      lng,
      description,
      userAccessToken,
      headline,
      postType,
      fileMetas,
      tags,
    },
  })
}

export function useCreateComment () {
  return useMutation<putCreatePostQuery, putCreatePostQueryVariables>(PUT_CREATE_POST)
}
