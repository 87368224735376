import React from 'react'
import { getAllTags } from '../gql/queries/getTags'

const Search = () => {
  getAllTags(false).then(data => console.log('%cSearch.tsx line:5 data', 'color: #007acc;', data))

  return (
    <div>Search</div>
  )
}

export default Search
// import { Box, Button, Collapse, Container, Flex, Heading, Tag, TagLabel, VStack } from '@chakra-ui/react'
// import { IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from '@ionic/react'
// import { chevronDownOutline, chevronUpOutline, search } from 'ionicons/icons'
// import qs from 'query-string'
// import React, { useEffect, useMemo, useState } from 'react'
// import { Link } from 'react-router-dom'
// import Header from '../components/Header'
// import { calculateTagColor } from '../components/Post/subComponents/PostContent'
// import type { ITagType } from '../gql/queries/getTags'
// import { useTags } from '../gql/queries/getTags'
// import type { getTagsQuery_getTribelloTagListing_edges as ITag } from '../gql/queries/__generated__/getTagsQuery'

// const Search: React.FC = () => {
//   const [searchText, setSearchText] = useState('')
//   const [selectedTags, setSelectedTags] = useState<number[]>([])
//   const [filteredTags, setFilteredTags] = React.useState<Partial<Record<ITagType, ITag[]>>>({})
//   const [isCollapsed, setIsCollapsed] = useState<boolean[]>([])
//   const { loading, data } = useTags(JSON.stringify({
//     $and: [
//       { tagType: { $not: 'hidden' } },
//       { tagType: { $not: 'category' } },
//     ],
//   }))

//   const tagTypes = useMemo(() => {
//     const types = new Set<ITagType>()

//     if (data?.getTribelloTagListing?.edges) {
//       data.getTribelloTagListing.edges.forEach((edge) => {
//         types.add(edge!.node!.tagType! as ITagType)
//       })
//     }

//     const map = ['type', 'region', 'interest', 'media']
//     return Array.from(types).sort((a, b) => map.indexOf(a) - map.indexOf(b))
//   }, [data])

//   useEffect(() => {
//     setIsCollapsed(Array(tagTypes.length).fill(true))
//     if (!loading && data?.getTribelloTagListing?.edges) {
//       tagTypes.forEach((tagType) => {
//         const filteredTagList = data.getTribelloTagListing?.edges?.filter((edge) => edge?.node?.tagType === tagType)
//         setFilteredTags((prevFilteredTags) => {
//           return {
//             ...prevFilteredTags,
//             [tagType]: filteredTagList,
//           }
//         })
//       })
//     }
//   }, [loading, data, tagTypes])

//   const link = useMemo(() => ({
//     tags: selectedTags.length > 0 ? JSON.stringify([...selectedTags.map(e => String(e))]) : undefined,
//     searchTerm: searchText || undefined,
//   }), [searchText, selectedTags])

//   const tagTypeTitles = useMemo(() => ({
//     region: 'Welche Region möchtest du besuchen?',
//     interest: 'Welches Thema interessiert dich?',
//     media: 'Welches Medium möchtest du anschauen?',
//     type: 'Was willst du machen?',
//     other: 'Anderes',
//     category: 'Anderes',
//     '': 'Anderes',
//   }), [])

//   return (
//     <IonPage>
//       <IonHeader>
//         <Header/>
//       </IonHeader>
//       <IonContent>
//         <Container maxW="container.md" h="calc(100%-56px-40px)%" pos="relative">
//           <VStack spacing={5} alignSelf="flex-start" alignItems="flex-start" mt={6}>
//             {/* <IonSearchbar placeholder="Suchbegriff eingeben" value={searchText} onIonChange={e => setSearchText(e.detail.value!)}/> */}
//             {/* <Divider/> */}
//             <Heading alignSelf="flex-start" size="lg">Welche Dogstories möchtest du entdecken?</Heading>
//             <Flex flexDir="column">
//               {Array.from(tagTypes).map((tagType, i) => (
//                 <Box key={tagType}>
//                   <Heading
//                     size="md"
//                     userSelect="none"
//                     cursor="pointer"
//                     onClick={() => setIsCollapsed([...isCollapsed.map((el, ind) => (ind === i ? !el : el))])}
//                     mt={3}
//                     mb={3}
//                   >{tagTypeTitles[tagType]} <IonIcon icon={isCollapsed[i] ? chevronDownOutline : chevronUpOutline} size="small"/>
//                   </Heading>
//                   <Box mb={4}>
//                     <Collapse in={!isCollapsed[i]}>
//                       <Flex css="gap: var(--chakra-space-2)" wrap="wrap">
//                         {filteredTags[tagType]?.map((tag) => (
//                           <Tag
//                             cursor="pointer"
//                             size="lg"
//                             borderRadius="full"
//                             variant={selectedTags.includes(Number(tag.node!.id!)) ? 'solid' : 'subtle'}
//                             onClick={() => setSelectedTags(selectedTags.includes(Number(tag.node!.id!)) ? [...selectedTags.filter(id => id !== Number(tag.node!.id!))] : [...selectedTags, Number(tag.node!.id!)])}
//                             colorScheme={calculateTagColor(tag.node?.tagType as ITagType)}
//                             key={tag.node?.id}
//                           >
//                             <TagLabel>{tag.node?.name}</TagLabel>
//                           </Tag>
//                         ))}
//                       </Flex>
//                     </Collapse>
//                   </Box>
//                 </Box>
//               ))}
//             </Flex>

//           </VStack>
//         </Container>
//         <Container
//           maxW="container.md"
//           pos="sticky"
//           bottom="10px"
//         >
//           <Button
//             w="full"
//             as={Link}
//             to={`/posts/?${qs.stringify(link)}`}
//             colorScheme="primary"
//             alignSelf="flex-end"
//             leftIcon={<IonIcon icon={search}/>}
//           >
//             Suchen
//           </Button>
//         </Container>
//       </IonContent>
//     </IonPage>
//   )
// }

// export default Search
