import { Avatar, Box, Button, Center, Container, Heading, HStack, Input, Skeleton, SkeletonCircle, Text, Textarea, useToast, VStack } from '@chakra-ui/react'
import { IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from '@ionic/react'
import type { UploadResult } from '@uppy/core'
import dayjs from 'dayjs'
import locale from 'dayjs/locale/de'
import { camera } from 'ionicons/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import type { IFile } from '../components/Fab'
import FileInputButton from '../components/FileInputButton'
import Header from '../components/Header'
import ProfileOptions from '../components/ProfileOptions'
import UserPosts from '../components/UserPosts'
import { useUpdateBio } from '../gql/mutations/putBio'
import { usePutProfileImage } from '../gql/mutations/putProfileImage'
import { useUpdateUser } from '../gql/mutations/putUserUpdate'
import { usePosts } from '../gql/queries/getPosts'
import { useUserById } from '../gql/queries/getUser'
import { getCompressedImageUrl } from '../helper/asset'
import { useUser } from '../helper/auth'
import { filterBuilder } from '../helper/filterBuilder'
void locale // so it doesnt get omited from build

const Profile: React.FC = () => {
  const user = useUser()
  const { userId } = useParams<{ userId: string }>()
  const toast = useToast()

  const history = useHistory()

  useEffect(() => {
    if (!user.loading && !user.isLoggedIn) {
      history.push('/login')
    }
  }, [user])

  const pageRef = useRef<HTMLIonContentElement>(null)

  const userData = useUserById(Number(userId), true)
  const customer = useMemo(() => userData.data?.userById?.user?.customer, [userData.data])

  const [isEditing, setIsEditing] = useState(false)

  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const bioRef = useRef<HTMLTextAreaElement>(null)

  const [updateUser, updateUserResult] = useUpdateUser()
  const [updateBio, updateBioResult] = useUpdateBio()
  const [updateImage, updateImageResult] = usePutProfileImage()
  const [uploadedNewImage, setUploadedNewImage] = useState<Blob>()

  const { data: userPosts } = usePosts({ first: 50, ...filterBuilder({ byUser: userId }) })

  const firstName = useMemo(() => {
    if (user.data?.user?.id === userId) {
      return updateUserResult.data?.userUpdate?.user?.customer?.firstname ?? customer?.firstname
    }
    return customer?.firstname
  }, [customer, updateUserResult.data, user.data, userId])

  const lastName = useMemo(() => {
    if (user.data?.user?.id === userId) {
      return updateUserResult.data?.userUpdate?.user?.customer?.lastname ?? customer?.lastname
    }
    return customer?.lastname
  }, [customer, updateUserResult.data, user.data, userId])

  const bio = useMemo(() => {
    if (user.data?.user?.id === userId) {
      return updateBioResult.data?.createBioCustomer?.customer?.bio ?? customer?.bio
    }
    return customer?.bio
  }, [customer, updateBioResult.data, user.data, userId])

  const [loadingEdit, isLoadingEdit] = useState(false)
  const [updatedImage, setUpdatedImage] = useState<IFile>()

  const handleSubmit = useCallback(async () => {
    isLoadingEdit(true)

    if (user.token) {
      await updateUser({
        variables: {
          userAccessToken: user.token,
          customer: {
            firstname: firstNameRef.current?.value ?? customer?.firstname ?? '',
            lastname: lastNameRef.current?.value ?? customer?.lastname ?? '',
            gender: 'male',
            phoneNumber: '',
            email: customer?.email ?? '',
          },
        },
      })

      if (typeof bioRef.current?.value === 'string') {
        await updateBio({
          variables: {
            bio: bioRef.current.value,
            token: user.token,
          },
        })
      }

      if (updatedImage) {
        const imageId = customer?.images?.[0]?.id
        const oldImageId = imageId ? Number(imageId) : undefined

        await updateImage({
          variables: {
            token: user.token,
            fileMeta: {
              mimeType: updatedImage.mimeType,
              name: updatedImage.name,
              url: updatedImage.url,
            },
            oldImageId,
          },
        })

        setUploadedNewImage(updatedImage.rawFile)
      }
    }

    setIsEditing(false)
    isLoadingEdit(false)
  }, [user.token, customer, updateUser, updateBio, updateImage, updatedImage])

  const handleUploadComplete = useCallback((result: UploadResult) => {
    const file = result.successful[0]
    if (typeof file !== 'undefined') {
      setUpdatedImage({ name: file.name, url: file.uploadURL, mimeType: file.data.type, rawFile: file.data })
    }
  }, [setUpdatedImage])

  const profileImageUrl = useMemo(() => {
    if (isEditing && updatedImage) {
      return URL.createObjectURL(updatedImage.rawFile)
    }

    if (uploadedNewImage) {
      return URL.createObjectURL(uploadedNewImage)
    }

    return getCompressedImageUrl(customer?.images?.[0]?.fullpath)
  }, [uploadedNewImage, customer, isEditing, updatedImage])

  return (
    !user.loading && user.isLoggedIn
      ? (
        <IonPage ref={pageRef}>
          <IonHeader>
            <Header/>
          </IonHeader>
          <IonContent fullscreen>
            <Container maxW="container.md">
              <VStack py="1em" spacing="3" align="start">
                {
              isEditing
                ? (
                  <Box pos="relative" role="group" cursor="pointer">
                    <FileInputButton
                      id="ProfileImageUpload"
                      singleFile
                      events={{
                        complete: handleUploadComplete,
                        'restriction-failed': () => toast({
                          title: 'Warnung',
                          status: 'warning',
                          description: 'Das selbe Bild kann nicht mehr als 2 Mal hochgeladen werden.',
                        }),
                      }}
                    />
                    <Avatar opacity={0.5} transition=".3s" border="solid 2px transparent" size="xl" src={profileImageUrl} _groupHover={{ border: 'solid 2px var(--ion-text-color)' }}/>
                    <Center opacity={0.5} transition=".75s" w="100%" h="100%" top={0} left={0} pos="absolute" fontSize="40px" _groupHover={{ opacity: 1 }}>
                      <IonIcon icon={camera}/>
                    </Center>
                  </Box>
                  )
                : (
                  <SkeletonCircle size="96px" isLoaded={Boolean(customer)}>
                    <Avatar size="xl" src={profileImageUrl}/>
                  </SkeletonCircle>
                  )
            }
                <HStack>
                  {isEditing
                    ? (
                      <>
                        <Input placeholder="Vorname" defaultValue={firstName ?? ''} ref={firstNameRef}/>
                        <Input placeholder="Nachname" defaultValue={lastName ?? ''} ref={lastNameRef}/>
                      </>
                      )
                    : (
                      <>
                        <Skeleton isLoaded={Boolean(customer)}>
                          <Heading size="lg">
                            {firstName ?? 'John'} {lastName ?? 'Doe'}
                          </Heading>
                        </Skeleton>
                        <ProfileOptions
                          userId={userId}
                          onEdit={() => {
                            setIsEditing(true)
                          }}
                        />
                      </>
                      )}
                </HStack>
                {isEditing
                  ? (
                    <>
                      <Textarea placeholder="Erzähle etwas über dich..." ref={bioRef} defaultValue={bio ?? ''}/>
                      <HStack justifyContent="flex-end" w="100%">
                        <Button onClick={() => setIsEditing(false)}>Abbrechen</Button>
                        <Button colorScheme="primary" onClick={handleSubmit} isLoading={loadingEdit}>Speichern</Button>
                      </HStack>
                    </>
                    )
                  : (
                    <>
                      <Skeleton isLoaded={Boolean(customer)} w="100%">
                        <Text>{bio ?? '-'}</Text>
                      </Skeleton>
                      <Skeleton isLoaded={Boolean(customer)}>
                        <Text opacity={0.55} mt="0!important">
                          Beigetreten {dayjs(Number(customer?.creationDate) * 1000).locale('de').format('MMMM YYYY')}
                        </Text>
                      </Skeleton>
                    </>
                    )}
                <UserPosts posts={userPosts} customer={customer}/>
                {/* <IonRouterLink href={`/posts?byUser=${userId}`} color="inherit">
                <Button colorScheme="primary">Alle Posts von {firstName ?? 'John'} anzeigen</Button>
              </IonRouterLink> */}
              </VStack>
            </Container>
          </IonContent>
        </IonPage>
        )
      : null)
}

export default Profile
