import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import React, { useMemo, createContext, useContext, useState } from 'react'
// @ts-expect-error There are no types...
import ImageCompressor from 'uppy-plugin-image-compressor'
import { useUser } from './auth'
import { UPPY_ENDPOINT } from './enviroment'

const instances: Partial<Record<string, Uppy>> = {
  testEntry: new Uppy(),
}

export function useUppyConfig (id: string, singleFile = false) {
  const user = useUser()
  return useMemo(() => {
    if (instances[id]) {
      const uppy = instances[id]!
      uppy.setOptions({
        autoProceed: true,
        restrictions: {
          allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'],
          maxNumberOfFiles: singleFile ? 1 : 10,
          maxFileSize: 300 * 1024 * 1024,
        },
      })
      uppy.getPlugin('Tus')?.setOptions({
        endpoint: UPPY_ENDPOINT,
        removeFingerprintOnSuccess: true,
        headers: { Authorization: `Bearer ${user.token ?? ''}` },
      })
      return uppy
    } else {
      const uppy = new Uppy({
        autoProceed: true,
        restrictions: {
          allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'],
          maxNumberOfFiles: singleFile ? 1 : 10,
          maxFileSize: 300 * 1024 * 1024,
        },
      })
      uppy.use(Tus, {
        endpoint: UPPY_ENDPOINT,
        removeFingerprintOnSuccess: true,
        headers: { Authorization: `Bearer ${user.token ?? ''}` },
      })
      uppy.use(ImageCompressor, {
        maxHeight: 750,
        quality: 0.6,
      })

      instances[id] = uppy
      return uppy
    }
  }, [user.token, singleFile, id])
}

export interface IFile {
  name: string
  url: string
  mimeType: string
  rawFile: File | Blob
}

function _useUpload () {
  const [files, setFiles] = useState<IFile[]>([])
  const [uploadModalOpen, setUploadModalOpen] = useState(false)

  return {
    files,
    setFiles,
    uploadModalOpen,
    setUploadModalOpen,
  }
}

export const UploadContext = createContext<Partial<ReturnType<typeof _useUpload>>>({})

export function useUpload () {
  return useContext(UploadContext)
}

export const UploadProvider: React.FC = ({ children }) => {
  const res = _useUpload()

  return (
    <UploadContext.Provider value={res}>
      {children}
    </UploadContext.Provider>
  )
}
