import FocusLock from '@chakra-ui/focus-lock'
import { Button, HStack, Spacer } from '@chakra-ui/react'
import React, { useState } from 'react'
import { updateComment } from '../../../gql/mutations/putUpdateCommentQuery'
import { getAuthToken } from '../../../helper/auth'
import MentionInput from '../../MentionInput'

interface IProps {
  value: string
  commentId: string | number
  setEditing: (editing: boolean) => void
  onCommentEdited: (content: string) => void
}
const CommentContentEditing: React.FC<IProps> = ({ value, setEditing, commentId, onCommentEdited }) => {
  const [content, setContent] = useState(value)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateComment(commentId, content, getAuthToken())
    onCommentEdited(content)
    setEditing(false)
  }
  return (
    <form onSubmit={handleSubmit}>
      <MentionInput
        onChange={(e) => setContent(e.target.value)}
        value={content}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
      />
      <Spacer mb={3}/>
      <HStack spacing={4} justifyContent="flex-end">
        <Button onClick={() => setEditing(false)}>Abbrechen</Button>
        <Button colorScheme="primary" type="submit">Speichern</Button>
      </HStack>
    </form>
  )
}

export default CommentContentEditing
