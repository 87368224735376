import { useQuery, gql } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getHotelQuery, getHotelQueryVariables } from './__generated__/getHotelQuery'

// TODO: Use @include(isDetail) for fields that are only required on detail view, to keep requests in feed smaller
export const HOTEL_FRAGMENT = gql`
fragment HotelFragment on object_TribelloHotel {
  id
  name
  description
  stars
  board
  likesCount
  geoPoint{
    latitude
    longitude
  }
  images {
    ... on asset {
      fullpath(thumbnail:"hotel")
      mimetype
    }
  }
  equipment {
    text
  }
  highlights {
    text
  }
  websiteTarget
  website {
    text
    path
  }
  tags {
    ...on object_TribelloTag {
      name
      id
      tagType {
        ...on object_TribelloTagType {
          name
          description
          id
        }
      }
    }
  }
  region
  country {
    isoCode
  }
}
`

export const GET_HOTEL = gql`
  query getHotelQuery ($id: Int!) {
    getTribelloHotel (id: $id) {
      ...HotelFragment
    }
  }

  ${HOTEL_FRAGMENT}
`

export function getHotel (id: number | string) {
  return apolloClient.query<getHotelQuery, getHotelQueryVariables>({
    query: GET_HOTEL,
    variables: {
      id: Number(id),
    },
  })
}

export function useHotel (id: number | string) {
  return useQuery<getHotelQuery, getHotelQueryVariables>(GET_HOTEL, {
    variables: {
      id: Number(id),
    },
  })
}
