import { Box, Divider, Text, Flex, Heading, Image, Input, useColorModeValue, VStack } from '@chakra-ui/react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import type { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import Downshift from 'downshift'
import 'leaflet/dist/leaflet.css'
import type mapboxgl from 'mapbox-gl'
import React, { useCallback, useRef, useState } from 'react'
import type { MapRef } from 'react-map-gl'
import Map, { NavigationControl, Marker } from 'react-map-gl'
import { useDebounce, useGeolocation } from 'react-use'
import { MAPBOX_GL_TOKEN, MAPBOX_TOKEN } from '../../../helper/enviroment'

const Geolocation: React.FC<{headline: string, image: string, description: string, onCoordinatesChanged: (latlng: [number, number]) => void}> = ({ description, headline, image, onCoordinatesChanged }) => {
  const geolocation = useGeolocation()
  const [locations, setLocations] = useState<GeocodeFeature[]>([])
  const mapRef = useRef<MapRef>(null)
  const [val, setVal] = useState('')
  const [markerCenter, setMarkerCenter] = useState<[number, number]>([geolocation.longitude ?? 12.373155889015592, geolocation.latitude ?? 47.45156487785928])
  const [placeName, setPlaceName] = useState('Kirchberger Straße 8, 6370 Kitzbühel, Austria')
  const selectedColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100')

  const handleReverseGeocode = useCallback(async ({ lat, lng }: mapboxgl.LngLat) => {
    const geocoder = mbxGeocoding({ accessToken: MAPBOX_TOKEN })

    const response = await geocoder.reverseGeocode({
      query: [lng, lat],
      limit: 1,
    }).send()

    setMarkerCenter([lng, lat])
    onCoordinatesChanged([lng, lat])
    setPlaceName(response.body.features[0].place_name)
  }, [onCoordinatesChanged])

  const fetchGeocode = useCallback(async () => {
    if (val) {
      console.log('fetching geocode...')
      const geocodingClient = mbxGeocoding({
        accessToken: MAPBOX_TOKEN,
      })
      const response = await geocodingClient
        .forwardGeocode({
          query: val,
          autocomplete: true,
          limit: 5,
        })
        .send()
      setLocations(response.body.features)
      console.log('fetched geocode', response.body.features)
    }
  }, [val])

  useDebounce(() => {
    console.log('debounce')
    fetchGeocode()
  }, 400, [val])

  const handleLocationChange = useCallback((e: GeocodeFeature | null) => {
    if (!e) return
    mapRef.current?.flyTo({ center: { lat: e.center[1], lng: e.center[0] }, speed: 3 })
    setMarkerCenter(e.center as [number, number])
    onCoordinatesChanged(e.center as [number, number])
    setPlaceName(e.place_name)
  }, [onCoordinatesChanged])

  return (
  // overflow is a hack because ionic modal is not scrollable otherwise
    <Box h="100%" w="100%" overflowY="auto">
      <Heading m={3} as="h3" size="lg">Wo fand deine Dogstory statt?</Heading>
      <Box m={3}>
        <Downshift
          onChange={(selectedItem: GeocodeFeature | null) => handleLocationChange(selectedItem)}
          itemToString={(item) => (item ? item.place_name : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            getLabelProps,
            getToggleButtonProps,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
            isOpen,
          }) => (
            <Box w="full">
              <div {...getRootProps(undefined, { suppressRefError: true })}>
                <label {...getLabelProps()}>Suche nach Ort</label>
                {/* @ts-expect-error types from component are incorrect; this works. */}
                <Input w="full" value={val} {...getInputProps({ onChange: (e) => { setVal(e.currentTarget.value) } })}/>
              </div>
              <VStack
                position="absolute"
                left={0}
                bgColor="var(--ion-toolbar-background)"
                spacing={3}
                w="calc(100% - var(--chakra-space-6))"
                m={3}
                {...getMenuProps()}
                zIndex={5}
              >
                {isOpen
                  ? (
                    <>
                      {locations
                        .map((item, index) => (
                          <Flex
                            w="full"
                            justify="flex-start"
                            borderRadius="md"
                            marginInlineStart={2}
                            marginInlineEnd={2}
                            paddingInlineStart={2}
                            paddingInlineEnd={2}
                            paddingTop={2}
                            paddingBottom={2}
                            cursor="pointer"
                            bgColor={highlightedIndex === index && selectedColor}
                            key={item.id}
                            {...getItemProps({
                              item,
                              index,
                            })}
                          >
                            <span>{item.place_name}</span>
                          </Flex>
                        ))}

                    </>
                    )
                  : null}
              </VStack>
            </Box>
          )}

        </Downshift>
        {/* <AutoComplete openOnFocus disableFilter onChange={(value, obj) => handleLocationChange((obj as any).value)}>
          <AutoCompleteInput variant="filled" onChange={({ currentTarget }) => { setVal(currentTarget.value) }}/>
          <AutoCompleteList>
            {locations.map((location, cid) => (
              <AutoCompleteItem
                onClick={() => handleLocationChange(JSON.stringify(location.center))}
                key={location.id}
                value={JSON.stringify(location.center)}
                label={location.place_name}
                textTransform="capitalize"
              >
                {location.place_name}
              </AutoCompleteItem>
            ))}
          </AutoCompleteList>
        </AutoComplete> */}
      </Box>
      <Map
        onClick={e => handleReverseGeocode(e.lngLat)}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_GL_TOKEN}
        initialViewState={{
          latitude: geolocation.latitude ?? markerCenter[1],
          longitude: geolocation.longitude ?? markerCenter[0],
          zoom: 6,
        }}
        style={{ width: '100%', height: 400 }}
        onRender={(map) => map.target.resize({ width: '100%', height: 400 })}
        ref={mapRef}
      >
        <NavigationControl/>
        <Marker longitude={markerCenter[0]} latitude={markerCenter[1]} anchor="bottom" >
          <Image width="48px" height="48px" src="/assets/marker.png"/>
        </Marker>
      </Map>
      <Box m={3}>
        <Heading as="h2" size="md" mt={3}>
          Ausgewählte Addresse
        </Heading>
        <Text>{placeName}</Text>
      </Box>
    </Box>
  )
}

export default Geolocation
