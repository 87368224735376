import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putDeletePostQuery, putDeletePostQueryVariables } from './__generated__/putDeletePostQuery'

export const PUT_DELETE_POST = gql`
  mutation putDeletePostQuery($postId: Int!, $userAccessToken: String!) {
    deletePost(postId: $postId, userAccessToken: $userAccessToken) {
      success
    }
  }
`

export function deletePost (postId: string | number, userAccessToken: string) {
  return apolloClient.query<putDeletePostQuery, putDeletePostQueryVariables>({
    query: PUT_DELETE_POST,
    variables: {
      postId: Number(postId),
      userAccessToken,
    },
  })
}

export function useDeletePost () {
  return useMutation<putDeletePostQuery, putDeletePostQueryVariables>(PUT_DELETE_POST)
}
