
import Cookies from 'js-cookie'
import { getUserByToken } from '../gql/queries/getUser'
import { useAsyncRetry } from 'react-use'
import { useLogin } from '../gql/mutations/putLogin'
import React, { createContext, useContext } from 'react'
import { putCommentLike, putCommentUnlike, putHotelLike, putHotelUnlike, putInspirationLike, putInspirationUnlike, putPostLike, putPostUnlike } from '../gql/mutations/putLike'
import { getIsLiked } from '../gql/queries/getIsPostLiked'
import { useCreateUser } from '../gql/mutations/putCreateUser'
import { createHotelBookmark, createInspirationBookmark, createPostBookmark, removeHotelBookmark, removeInspirationBookmark, removePostBookmark } from '../gql/mutations/putBookmark'
import type { putPostLikeQuery } from '../gql/mutations/__generated__/putPostLikeQuery'
import type { ApolloQueryResult } from '@apollo/client'
import type { putCommentLikeQuery } from '../gql/mutations/__generated__/putCommentLikeQuery'
import type { putHotelLikeQuery } from '../gql/mutations/__generated__/putHotelLikeQuery'
import type { putPostUnlikeQuery } from '../gql/mutations/__generated__/putPostUnlikeQuery'
import type { putCommentUnlikeQuery } from '../gql/mutations/__generated__/putCommentUnlikeQuery'
import type { putHotelUnlikeQuery } from '../gql/mutations/__generated__/putHotelUnlikeQuery'
import { getIsBookmarked } from '../gql/queries/getIsBookmarked'
import type { putCreatePostBookmarkQuery } from '../gql/mutations/__generated__/putCreatePostBookmarkQuery'
import type { putCreateHotelBookmarkQuery } from '../gql/mutations/__generated__/putCreateHotelBookmarkQuery'
import type { putCreateInspirationBookmarkQuery } from '../gql/mutations/__generated__/putCreateInspirationBookmarkQuery'
import type { putRemoveBookmarkQuery } from '../gql/mutations/__generated__/putRemoveBookmarkQuery'
import type { putRemoveHotelBookmarkQuery } from '../gql/mutations/__generated__/putRemoveHotelBookmarkQuery'
import type { putRemoveInspirationBookmarkQuery } from '../gql/mutations/__generated__/putRemoveInspirationBookmarkQuery'
import type { putInspirationLikeQuery } from '../gql/mutations/__generated__/putInspirationLikeQuery'
import type { putInspirationUnlikeQuery } from '../gql/mutations/__generated__/putInspirationUnlikeQuery'

export function setAuthToken (token: string) {
  Cookies.set('token', token)
}

export function getAuthToken () {
  return Cookies.get('token') ?? ''
}

export function removeAuthToken () {
  Cookies.remove('token')
}

export function setAuthTokenStamp (timestamp: string) {
  Cookies.set('token_stamp', timestamp)
}

export function getAuthTokenStamp () {
  return Cookies.get('token_stamp') ?? ''
}

export function removeAuthTokenStamp () {
  Cookies.remove('token_stamp')
}

export async function getUser () {
  const token = getAuthToken()
  if (!token) return null

  const response = await getUserByToken(token)
  if (!response.errors) return response.data.userByToken
}

function _useUser () {
  const res = useAsyncRetry(getUser)
  const [loginMutation, loginResult] = useLogin()
  const [registerMutation, registerResult] = useCreateUser()

  function logout () {
    removeAuthToken()
    removeAuthTokenStamp()
    res.retry()
  }

  async function login (email: string, password: string) {
    const loginRes = await loginMutation({
      variables: {
        email,
        password,
      },
    })

    if (loginRes.errors) return { error: loginRes.errors.length > 0 ? loginRes.errors[0].message : 'ERR_LOGIN_FAILED' }

    if (loginRes.data?.userLogin?.tokenValidUntil) {
      setAuthTokenStamp(loginRes.data.userLogin.tokenValidUntil.toString())
    }

    if (loginRes.data?.userLogin?.token) {
      setAuthToken(loginRes.data.userLogin.token)
      res.retry()
      return {
        token: loginRes.data.userLogin.token,
        isActive: loginRes.data.userLogin.isActive,
        userId: loginRes.data.userLogin.customer?.user?.id,
      }
    }
  }

  async function register (pw: string, pwRepeat: string, firstName: string, lastName: string, email: string, emailRepeat: string, locale: string, termsAccepted: boolean) {
    const loginRes = await registerMutation({
      variables: {
        pw,
        pwRepeat,
        firstName,
        lastName,
        email,
        emailRepeat,
        locale,
        termsAccepted,
      },
    })

    if (loginRes.errors) return { error: loginRes.errors.length > 0 ? loginRes.errors[0].message : 'ERR_LOGIN_FAILED' }

    if (loginRes.data?.userRegister?.tokenValidUntil) {
      setAuthTokenStamp(loginRes.data.userRegister.tokenValidUntil.toString())
    }

    if (loginRes.data?.userRegister?.token) {
      setAuthToken(loginRes.data.userRegister.token)
      res.retry()
      return {
        token: loginRes.data.userRegister.token,
        isActive: loginRes.data.userRegister.isActive,
        userId: loginRes.data.userRegister.customer?.user?.id,
      }
    }
  }

  async function like (id: string | number, type: 'post' | 'comment' | 'hotel' | 'inspiration') {
    const user = res.value
    if (!user) return { error: 'ERR_NOT_LOGGED_IN' }

    // TODO: outsource this into a function that can handle like and unlike to avoid duplicate code
    const handleRes = async (response: ApolloQueryResult<putPostLikeQuery | putCommentLikeQuery | putHotelLikeQuery | putInspirationLikeQuery>) => {
      if (response.errors) return { error: response.errors.length > 0 ? response.errors[0].message : 'ERR_LIKE_FAILED' }

      const isLiked = await getIsLiked(getAuthToken(), type, id)

      if (isLiked.errors) return { error: isLiked.errors.length > 0 ? isLiked.errors[0].message : 'ERR_LIKE_INFO_FAILED' }

      return {
        data: response.data.createLike,
        isLiked: isLiked.data.likedByUser?.liked,
      }
    }

    if (type === 'post') {
      return putPostLike(id, getAuthToken()).then(handleRes)
    }
    if (type === 'comment') {
      return putCommentLike(id, getAuthToken()).then(handleRes)
    }
    if (type === 'inspiration') {
      return putInspirationLike(id, getAuthToken()).then(handleRes)
    }
    // disabled for readability and code style
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'hotel') {
      return putHotelLike(id, getAuthToken()).then(handleRes)
    }
  }

  async function unlike (id: string | number, type: 'post' | 'comment' | 'hotel' | 'inspiration') {
    const user = res.value
    if (!user) return { error: 'ERR_NOT_LOGGED_IN' }

    const handleRes = async (response: ApolloQueryResult<putPostUnlikeQuery | putCommentUnlikeQuery | putHotelUnlikeQuery | putInspirationUnlikeQuery>) => {
      if (response.errors) return { error: response.errors.length > 0 ? response.errors[0].message : 'ERR_UNLIKE_FAILED' }

      const isLiked = await getIsLiked(getAuthToken(), type, id)
      if (isLiked.errors) return { error: isLiked.errors.length > 0 ? isLiked.errors[0].message : 'ERR_UNLIKE_INFO_FAILED' }

      return {
        data: response.data.deleteLike,
        isLiked: isLiked.data.likedByUser?.liked,
      }
    }

    if (type === 'post') {
      return putPostUnlike(id, getAuthToken()).then(handleRes)
    }
    if (type === 'comment') {
      return putCommentUnlike(id, getAuthToken()).then(handleRes)
    }
    if (type === 'inspiration') {
      return putInspirationUnlike(id, getAuthToken()).then(handleRes)
    }
    // disabled for readability and code style
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'hotel') {
      return putHotelUnlike(id, getAuthToken()).then(handleRes)
    }
  }

  async function bookmark (id: string | number, type: 'post' | 'hotel' | 'inspiration') {
    const user = res.value
    if (!user) return { error: 'ERR_NOT_LOGGED_IN' }

    const handleRes = async (response: ApolloQueryResult<putCreatePostBookmarkQuery | putCreateHotelBookmarkQuery | putCreateInspirationBookmarkQuery>) => {
      if (response.errors) return { error: response.errors.length > 0 ? response.errors[0].message : 'ERR_BOOKMARK_FAILED' }

      const isBookmarked = await getIsBookmarked(getAuthToken(), type, id)
      if (isBookmarked.errors) return { error: isBookmarked.errors.length > 0 ? isBookmarked.errors[0].message : 'ERR_BOOKMARK_INFO_FAILED' }

      return {
        success: !!response.data.createBookmark?.success,
      }
    }

    if (type === 'post') {
      return createPostBookmark(id, getAuthToken()).then(handleRes)
    }
    if (type === 'hotel') {
      return createHotelBookmark(id, getAuthToken()).then(handleRes)
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'inspiration') {
      return createInspirationBookmark(id, getAuthToken()).then(handleRes)
    }
  }

  async function unbookmark (id: string | number, type: 'post' | 'hotel' | 'inspiration') {
    const user = res.value
    if (!user) return { error: 'ERR_NOT_LOGGED_IN' }

    const handleRes = async (response: ApolloQueryResult<putRemoveBookmarkQuery | putRemoveHotelBookmarkQuery | putRemoveInspirationBookmarkQuery>) => {
      if (response.errors) return { error: response.errors.length > 0 ? response.errors[0].message : 'ERR_UNBOOKMARK_FAILED' }

      const isBookmarked = await getIsBookmarked(getAuthToken(), type, id)
      if (isBookmarked.errors) return { error: isBookmarked.errors.length > 0 ? isBookmarked.errors[0].message : 'ERR_UNBOOKMARK_INFO_FAILED' }

      return {
        success: !!response.data.deleteBookmark?.success,
      }
    }

    if (type === 'post') {
      return removePostBookmark(id, getAuthToken()).then(handleRes)
    }
    if (type === 'hotel') {
      return removeHotelBookmark(id, getAuthToken()).then(handleRes)
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (type === 'inspiration') {
      return removeInspirationBookmark(id, getAuthToken()).then(handleRes)
    }
  }

  return {
    loading: res.loading || loginResult.loading,
    data: res.value,
    refresh: res.retry,
    isLoggedIn: !!res.value,
    token: getAuthToken(),
    logout,
    login,
    register,
    like,
    unlike,
    bookmark,
    unbookmark,
  }
}

export const UserContext = createContext<Partial<ReturnType<typeof _useUser>>>({
  loading: true,
})

export function useUser () {
  return useContext(UserContext)
}

export const UserProvider: React.FC = ({ children }) => {
  const res = _useUser()

  return (
    <UserContext.Provider value={res}>
      {children}
    </UserContext.Provider>
  )
}
