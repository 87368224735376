import { Box, Button, Center, Container, FormControl, FormHelperText, FormLabel, Input, useColorModeValue, VStack, Text, useToast } from '@chakra-ui/react'
import { IonContent, IonHeader, IonIcon, IonImg, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePutUserForgotPassword } from '../gql/mutations/putUserForgotPassword'
import { usePutUserResetPassword, usePutUserValidateToken } from '../gql/mutations/putUserResetPassword'

const ResetPassword: React.FC = () => {
  const logo = useColorModeValue('/assets/logo.svg', '/assets/logo-white.svg')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [userForgotPassword, userForgotPasswordReuslt] = usePutUserForgotPassword()
  const params = useParams<{ hash?: string }>()
  const hash = params.hash
  const [validateHash] = usePutUserValidateToken()
  const [resetPassword] = usePutUserResetPassword()
  const toast = useToast()
  const history = useHistory()

  const handleSubmitEmail = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    await userForgotPassword({
      variables: {
        email,
        locale: 'en',
        resetLink: location.href,
      },
    })

    setLoading(false)
  }, [email, userForgotPassword])

  const handleSubmitPasswort = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    if (!hash) return

    const res = await validateHash({
      variables: {
        passwordResetToken: hash,
      },
    })

    if (!res.data?.userValidateForgotPasswordToken?.valid) {
      return toast({
        title: 'Ungültiger Token',
        description: 'Der Token aus deiner Email ist ungültig oder bereits verwendet.',
        status: 'error',
      })
    }

    const resPw = await resetPassword({
      variables: {
        password,
        passwordRepeat: confirmPassword,
        token: hash,
      },
    })

    if (resPw.data?.userResetPassword?.token) {
      toast({
        title: 'Passwort geändert',
        description: 'Dein Passwort wurde erfolgreich geändert.',
        status: 'success',
      })

      history.push('/login')
    }

    setLoading(false)
  }, [password, confirmPassword, hash, validateHash, resetPassword, history, toast])

  const content = useMemo(() => {
    if (hash) {
      return (
        <VStack spacing={45} mt={15} alignItems="stretch">
          <Center flexDir="column">
            <IonImg src={logo} alt="Tribello Logo"/>
          </Center>
          <form onSubmit={e => handleSubmitPasswort(e)}>
            <VStack spacing={3}>
              <FormControl>
                <FormLabel>Neues Passwort</FormLabel>
                <Input type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                <FormHelperText>Dein neues Passwort.</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Passwort wiederholen</FormLabel>
                <Input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
                <FormHelperText>Bitte wiederhole dein neues Passwort.</FormHelperText>
              </FormControl>
              <Button isLoading={loading} alignSelf="flex-end" colorScheme="primary" type="submit">Passwort Zurücksetzen</Button>
            </VStack>
          </form>
        </VStack>
      )
    }

    if (userForgotPasswordReuslt.data?.userForgotPassword?.user?.id) {
      return (
        <Box>
          <Center fontSize="8em" color="green.500">
            <IonIcon icon={checkmarkCircle}/>
          </Center>
          <Text>Eine E-Mail wurde versendet und sollte in den nächsten Minuten eintreffen. Bitte überprüfe dein Postfach.</Text>
        </Box>
      )
    }

    return (
      <VStack spacing={45} mt={15} alignItems="stretch">
        <Center flexDir="column">
          <IonImg src={logo} alt="Tribello Logo"/>
        </Center>
        <form onSubmit={e => handleSubmitEmail(e)}>
          <VStack spacing={3}>
            <FormControl id="email">
              <FormLabel>E-Mail Adresse</FormLabel>
              <Input type="email" value={email} onChange={e => setEmail(e.target.value)}/>
              <FormHelperText>Deine E-Mail Adresse die du für die Anmeldung verwendet hast.</FormHelperText>
            </FormControl>
            <Button isLoading={loading} alignSelf="flex-end" colorScheme="primary" type="submit">Passwort Zurücksetzen</Button>
          </VStack>
        </form>
      </VStack>
    )
  }, [email, loading, userForgotPasswordReuslt.data?.userForgotPassword?.user?.id, handleSubmitEmail, logo, password, confirmPassword, hash, handleSubmitPasswort])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Passwort Vergessen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding bg-clear">
        <Container maxW="container.sm" bg="whites">
          {content}
        </Container>
      </IonContent>
    </IonPage>
  )
}

export default ResetPassword
