import { IonContent, IonHeader, IonPage } from '@ionic/react'
import React from 'react'
import Header from '../../Header'
import TagSelect from '../../TagSelect'

const Filters: React.FC<React.ComponentProps<typeof TagSelect>> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <Header/>
      </IonHeader>
      <IonContent>
        <TagSelect {...props}/>
      </IonContent>
    </IonPage>
  )
}

export default Filters
