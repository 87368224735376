import { useToast } from '@chakra-ui/react'
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import type { UploadResult, Uppy } from '@uppy/core'
import '@uppy/dashboard/dist/style.css'
import { closeCircle } from 'ionicons/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import type { Swiper as SwiperType } from 'swiper'
import SwiperCore, { Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import { createPost } from '../../gql/mutations/putPost'
import { useUser } from '../../helper/auth'
import type { IFile } from '../Fab'
import Geolocation from './subComponents/Geolocation'
import MediaUpload from './subComponents/MediaUpload'
import TagSelect from '../TagSelect'

SwiperCore.use([Virtual])

interface IUploadModalProps {
  isOpen: boolean
  presentingElemet: HTMLIonContentElement | HTMLIonRouterOutletElement | null
  onDismiss?: () => void
  files: IFile[]
  onUploadComplete?: (e: UploadResult) => void
  uppy: Uppy
}

const UploadModal: React.FC<IUploadModalProps> = ({ uppy, onUploadComplete, isOpen, presentingElemet, onDismiss }) => {
  const user = useUser()
  const toast = useToast()
  const [description, setDescription] = useState('')
  const [headline, setHeadline] = useState('')
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 })
  const [tags, setTags] = useState<number[]>([])
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const history = useHistory()
  const [activeIndex, setActiveIndex] = useState(0)

  const dismiss = useCallback(() => {
    setDescription('')
    setHeadline('')
    setCoordinates({ lat: 0, lng: 0 })
    setTags([])
    setActiveIndex(0)
    onDismiss?.()
  }, [onDismiss])

  const savePost = useCallback(async () => {
    if (!user.isLoggedIn) return toast({ status: 'error', description: 'Bitte einloggen, um diese Funktion nutzen zu können' })
    if (!description || !headline) return toast({ status: 'error', description: 'Alle Felder müssen ausgefüllt sein.' })

    const res = await uppy.upload()
    const files = res.successful.map(file => ({ name: file.name, url: file.uploadURL, mimeType: file.data.type, rawFile: file.data }))
    createPost(
      {
        description,
        userAccessToken: user.token!,
        headline,
        postType: files.length > -1 ? 'Image' : 'Text',
        fileMetas: files.map(({ rawFile, ...rest }) => rest),
        lat: coordinates.lat,
        lng: coordinates.lng,
        tags,
      },
    ).then(res => {
      if (res.data.createPost?.success) {
        toast({ status: 'success', description: 'Post erfolgreich erstellt' })
        onDismiss?.()
        history.push(`/posts/${res.data.createPost.post?.id ?? ''}`)
      }
    })
    setDescription('')
    setHeadline('')
    setTags([])
  }, [user.isLoggedIn, user.token, toast, description, headline, uppy, coordinates.lat, coordinates.lng, tags, onDismiss, history])

  const checkTextValidity = useCallback(() => {
    if (!description || !headline) return { valid: false, message: 'Beschreibung und Titel müssen ausgefüllt sein' }
    if (/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g.test(description)) return { valid: false, message: 'Beschreibung darf keine URL enthalten' }
    if (uppy.getFiles().length === 0) return { valid: false, message: 'Es muss mindestens ein Bild hochgeladen werden' }
    return { valid: true, message: '' }
  }, [description, headline, uppy])

  useEffect(() => {
    uppy.on('file-added', () => checkTextValidity())
    uppy.on('dashboard:file-edit-complete', () => checkTextValidity())
    uppy.on('file-removed', () => checkTextValidity())
  }, [uppy, checkTextValidity])

  const prevButton = useMemo(() => activeIndex > 0 && (
    <IonButtons slot="start">
      <IonButton onClick={() => swiper?.slidePrev()}>Zurück</IonButton>
    </IonButtons>
  ), [activeIndex, swiper])

  return (
    <IonModal mode="ios" onDidDismiss={() => dismiss()} swipeToClose={false} isOpen={isOpen} presentingElement={presentingElemet ?? undefined}>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => dismiss()}><IonIcon icon={closeCircle}/></IonButton>
          </IonButtons>
          {prevButton}
          <IonTitle>Neuer Post</IonTitle>
          <IonButtons slot="end">
            {/* eslint-disable-next-line */}
            <IonButton disabled={!checkTextValidity().valid} onClick={() => activeIndex <= 1 ? swiper?.slideNext() : savePost()}> { activeIndex <= 1 ? 'Weiter' : 'Posten'}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Swiper
          onActiveIndexChange={s => setActiveIndex(s.activeIndex)}
          onSwiper={setSwiper}
          style={{ width: '100%', height: '100%' }}
        // allowSlideNext={false}
        // allowSlidePrev={false}
          virtual={{
            enabled: true,
            cache: false,
            addSlidesAfter: 0,
            addSlidesBefore: 0,
          }}
          allowTouchMove={false}
        >
          <SwiperSlide style={{ overflow: 'hidden', overflowY: 'auto' }}>
            <MediaUpload
              isValid={checkTextValidity()}
              uppy={uppy}
              headline={headline}
              description={description}
              onHeadlineChange={e => setHeadline(e.target.value)}
              onDescriptionChange={e => setDescription(e.target.value)}
            />
          </SwiperSlide>
          <SwiperSlide style={{ overflow: 'hidden', overflowY: 'auto' }}>
            <TagSelect enableTagCreation={true} showHiddenTagsInSearch={true} setTags={setTags} tags={tags}/>
          </SwiperSlide>
          <SwiperSlide style={{ overflow: 'hidden', overflowY: 'auto' }}>
            <Geolocation onCoordinatesChanged={coords => setCoordinates({ lng: coords[0], lat: coords[1] })} key={activeIndex} description={description} headline={headline} image="https://source.unsplash.com/random/250x250"/>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonModal>
  )
}

export default UploadModal
